import React from "react"
import { Link } from "gatsby"
import headerStyles from "../styles/components/header.module.scss"
import graphDesgn_logo from '../../content/images/graphDesgn_logo.webp'

export default function Header(props) {
  return (
    <header
      className={`${headerStyles.header} ${props.page === 'info' &&
        headerStyles.info_page}`}
    >
      <nav
        className={headerStyles.header__nav}
        role="navigation"
        aria-label="main navigation"
      >
      <div>
        <Link to="/">
          <h1><img src={graphDesgn_logo} alt="logo"/></h1>
        </Link>
        <h2>Le blog du design numérique engagé</h2>
        </div>
        <div><h3>
        <Link
          to={
            props.page === 'images'
              ? "/"
              : "/images"
          }
          activeClassName={headerStyles.navItemActive}
        >
          {props.page === 'images'
            ? "/"
            : "Pourquoi les images de ce site ne sont pas de bonne qualité*"}
        </Link>
        </h3>
        </div>

        <div id="wcb" class="carbonbadge"></div>
        <script src="https://unpkg.com/website-carbon-badges@1.1.3/b.min.js"></script>

        <div>
          <h1>
            <Link
              to={
                props.page === 'info'
                  ? "/"
                  : "/info"
              }
              activeClassName={headerStyles.navItemActive}
            >
              {props.page === 'info'
                ? "fermer"
                : "info"}
            </Link>
          </h1>
        </div>
      </nav>
    </header>
  )
}
